@import 'Styles/includes';

.Archive {
    $root: &;

    margin-bottom: 48px;

    @include media(M) {
        margin-bottom: 64px;
    }

    @include media(ML) {
        margin-bottom: 80px;
    }

    &__Wrap {
        @include wrap;
    }

    &__Filter {
        margin-top: 40px;
        margin-bottom: 40px;
    }

    &__Empty {
        margin-top: 40px;
        margin-bottom: 40px;
    }

    &__Grid {
        margin-top: 48px;
        margin-bottom: 32px;

        @include media(M) {
            margin-top: 64px;
            margin-bottom: 56px;
        }
    }

    &__Pagination {
        margin: 40px 0;
    }
}
