@import 'Styles/includes';

.Accordion {
    $root: &;

    position: relative;
    margin-bottom: -1px;
    border-top: 1px solid $grey-20;

    *:last-child & {
        border-bottom: 1px solid $grey-20;
    }

    &__Header {
        position: relative;
        width: 100%;
        padding: 21px 0;
        font-size: 2rem;
        font-weight: $bold;
        text-align: left;
        transition: color $transition;
        cursor: pointer;

        @include media(M) {
            padding-top: 24px;
            padding-bottom: 24px;
        }

        #{$root}--FactList & {
            @include media(M) {
                padding-left: 24px;
                padding-right: 24px;
            }
        }

        #{$root}--Expanded &,
        &:hover {
            &::before {
                opacity: 1;
            }
        }
    }

    &__Title {
        position: relative;
        padding-right: (32px + 12px);

        @include media(M) {
            padding-right: (40px + 16px);
        }
    }

    &__Link {
        @include textstyle(h5);
        display: inline-flex;
        align-items: center;
        position: relative;
    }

    &__LinkArrow {
        @extend %button--round-arrow-pink;

        flex: 0 0 auto;
        width: 32px;
        height: 32px;
        min-height: 32px;
        margin-right: 12px !important;

        @include media(M) {
            width: 40px;
            height: 40px;
            min-height: 40px;
            margin-right: 16px !important;
        }

        #{$root}__Link:hover & {
            @extend %button--round-arrow-pink, :hover;
        }
    }

    &__Content {
        position: relative;
    }

    &__Button {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        width: 32px;
        height: 32px;
        margin: auto;
        border: none;
        border-radius: 50%;
        background: none;
        box-shadow: none;
        transition: background $transition;
        cursor: pointer;

        @include media(M) {
            width: 40px;
            height: 40px;
        }

        #{$root}--FactList & {
            @include media(M) {
                right: 24px;
            }
        }

        #{$root}__Header:hover & {
            background: $red-5;

            @media(hover: none) {
                background: none !important;
            }

            #{$root}--FactList & {
                background: $red;
            }
        }

        &::before,
        &::after {
            content: '';
            display: block;
            position: absolute;
            top: 0px;
            bottom: 0;
            right: 0;
            left: 0;
            width: 16px;
            height: 2px;
            margin: auto;
            background: $red;
            transition: opacity $transition;

            #{$root}--FactList #{$root}__Header:hover & {
                background: white;

                @media(hover: none) {
                    background: $red;
                }
            }
        }

        &::after {
            transform: rotate(90deg);
        }

        #{$root}--Expanded & {
            &::after {
                opacity: 0;
            }
        }
    }
}
