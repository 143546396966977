@import 'Styles/includes';

.ArchiveCalendar {
    $root: &;

    margin-bottom: 48px;

    @include media(M) {
        margin-bottom: 64px;
    }

    @include media(ML) {
        margin-bottom: 80px;
    }

    &__Wrap {
        @include wrap;
    }

    &__Filter {
        margin-top: 40px;
        margin-bottom: 40px;
    }

    &__Title {
        @include textstyle(h4);

        margin-top: 48px;
        // text-transform: uppercase;
        // font-size: 1.4rem;
        // line-height: 1.14;
        // font-weight: 700;

        @include media(M) {
            margin-top: 64px;
        }
    }

    &__Empty {
        margin-top: 40px;
        margin-bottom: 40px;
    }

    &__Grid {
        margin-top: 24px;
        margin-bottom: 32px;

        @include media(M) {
            margin-top: 32px;
            margin-bottom: 56px;
        }
    }

    &__Pagination {
        margin: 40px 0;
    }

    &__ButtonContainer {
        text-align: center;
    }
}
