@import 'Styles/includes';

.Search {
    $root: &;

    margin-bottom: 48px;

    @include media(M) {
        margin-bottom: 64px;
    }

    @include media(ML) {
        margin-bottom: 80px;
    }

    &__Wrap {
        @include wrap;
    }

    &__Count {
        font-size: 1.2rem;
        font-weight: $bold;
        text-transform: uppercase;
        line-height: 1.33;
        letter-spacing: 0.24px;
        margin-top: 72px;

        @include media(M) {
            margin-top: 92px;
        }
    }

    &__Empty {
        @include textstyle(h3);

        height: 400px;
        width: 400px;
        display: grid;
        place-items: center;
    }

    &__Grid {
        margin-top: 24px;
        margin-bottom: 32px;

        @include media(M) {
            margin-top: 32px;
            margin-bottom: 56px;
        }
    }
}
