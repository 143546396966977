@import 'Styles/includes';

.CardLocal {
    $root: &;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;

    &__Header {
        position: relative;
        margin-bottom: 8px;
        padding-right: 120px;

        @include media(M) {
            padding-right: 130px;
        }
    }

    &__Type {
        @include textstyle(body-regular);

        color: $grey-60;
    }

    &__Title {
        @include textstyle(h3);

        color: $red;
    }

    &__Website {
        @include textstyle(body-regular);

        position: absolute;
        top: 0;
        right: 0;
        display: inline-block;
        text-decoration: underline;
        color: $red;
        font-weight: $bold;

        &:hover {
            text-decoration: none;
            color: $link-hover-color;
        }

        &::after {
            content: none;
        }

        @include media(M) {
            bottom: 8px;
        }
    }

    &__Content {
        @include textstyle(body-regular);
    }

    &__Infos {
        display: flex;
        flex-wrap: wrap;
        margin-left: -40px;
    }

    &__Info {
        flex: 0 0 auto;
        margin-left: 40px;
        margin-bottom: 24px;

        p:empty {
            display: none;
        }
    }

    &__InfoTitle {
        @include textstyle(h6);

        margin-top: 12px;
    }

    &__Text {
        margin-bottom: 16px;
    }

    &__Related {
        display: flex;
        flex-direction: column;
    }

    &__RelatedTitle {
        @include textstyle(h6);

        padding: 12px 0;
    }

    &__Link {
        color: $link-color;
        text-decoration: underline;
        transition: color $transition;

        &:hover {
            text-decoration: none;
            color: $link-hover-color;
        }
    }
}
