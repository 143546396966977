@import 'Styles/includes';

.LetterIndex {
    $root: &;

    &__Title {
        @include textstyle(h3);

        margin-top: 24px;

        @include media(M) {
            margin-top: 40px;
        }

        @include media(M) {
            margin-top: 56px;
        }
    }

    &__List {
        max-width: 800px;
        display: flex;
        flex-wrap: wrap;
        margin-top: 16px;
        margin-left: -2px;

        @include media(M) {
            margin-top: 24px;
        }
    }

    &__Item {
        margin-left: 2px;
        margin-bottom: 2px;
    }

    &__Link {
        @extend %button--tag;

        width: 48px;
        padding-left: 0 !important;
        padding-right: 0 !important;
        text-align: center;

        &--Active {
            @extend %button--tag-active;
        }

        &:hover {
            background-color: $grey-90;
            color: white;
        }
    }
}
