@import 'Styles/includes';

.Pagination {
    $root: &;

    &__List {
        display: flex;
        justify-content: center;
        margin-left: -4px;

        @include media(SL) {
            margin-left: -8px;
        }

        @include media(M) {
            margin-left: -16px;
        }
    }

    &__Item {
        display: block;
        margin-left: 4px;
        transition: opacity $transition;

        &--Disabled {
            opacity: 0;
        }

        @include media(SL) {
            margin-left: 8px;
        }

        @include media(M) {
            margin-left: 16px;
        }
    }

    &__Link {
        @include textstyle(body-small);

        position: relative;
        display: block;
        height: 32px;
        min-width: 32px;
        padding: 0 7px;
        color: $grey-90;
        font-size: 1.4rem;
        line-height: 30px;
        text-align: center;
        border: 1px solid $grey-40;
        border-radius: 4px;
        background: transparent;
        transition: background $transition, color $transition, border-color $transition;
        cursor: pointer;
        outline: none;

        @include media(SL) {
            height: 40px;
            min-width: 40px;
            font-size: 1.6rem;
            line-height: 38px;
        }

        @include media(M) {
            height: 48px;
            min-width: 48px;
            line-height: 48px;
        }

        #{$root}__Item:not(#{$root}__Item--Active) &:hover {
            border-color: $grey-90;
        }

        #{$root}__Item--Active & {
            color: white;
            border-color: $grey-90;
            background: $grey-90;
            cursor: default;
        }

        svg {
            width: 16px;
            height: 14px;
            fill: $grey-90;
            position: relative;
            top: 2px;
            transition: fill $transition;

            @include media(SL) {
                top: 1px;
                width: 18px;
                height: 14px;
            }

            @include media(M) {
                top: 0;
                width: 19px;
                height: 15px;
            }
        }

        #{$root}__Item--Prev &,
        #{$root}__Item--Next & {
            width: 32px;

            @include media(SL) {
                width: 40px;
            }

            @include media(M) {
                width: 48px;
            }
        }

        #{$root}__Item--Prev & svg {
            left: -1px;
            transform: rotate(180deg);
        }

        #{$root}__Item--Next & svg {
            right: -1px;
        }
    }

    &__Break {
        @include textstyle(body-small);

        display: block;
        position: relative;
        min-width: 14px;
        height: 32px;
        line-height: 54px;
        text-align: center;
        letter-spacing: 2px;
        border: none;
        background: transparent;

        &:hover {
            color: black;
        }

        @include media(SL) {
            height: 40px;
            line-height: 68px;
        }

        @include media(M) {
            height: 48px;
            line-height: 82px;
        }
    }
}
