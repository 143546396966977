@import 'Styles/includes';

.ArchiveVideo {
    $root: &;

    margin-bottom: 48px;

    @include media(M) {
        margin-bottom: 64px;
    }

    @include media(ML) {
        margin-bottom: 80px;
    }

    &__Wrap {
        @include wrap;
    }

    &__Content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-top: 40px;

        @include media(M) {
            padding-top: 64px;
        }

        @include media(L) {
            padding-top: 80px;
        }
    }

    &__Title {
        @include textstyle(h1);
    }

    &__Description {
        :global {
            @extend %richtext;
        }

        @include textstyle(body-regular);

        margin-top: 16px;
        max-width: 600px;
    }

    &__Filter {
        margin-top: 40px;
        margin-bottom: 40px;
    }

    &__Empty {
        margin-top: 40px;
        margin-bottom: 40px;
    }

    &__Grid {
        margin-top: 48px;
        margin-bottom: 32px;

        @include media(M) {
            margin-top: 64px;
            margin-bottom: 56px;
        }
    }

    &__Pagination {
        margin: 40px 0;
    }
}
