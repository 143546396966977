@import 'Styles/includes';

.FeaturedVideo {
    $root: &;

    position: relative;
    background: $red-5;

    &__Wrap {
        @include wrap;

        display: flex;
        flex-direction: column;
        position: relative;
        padding-top: 48px;
        padding-bottom: 48px;

        @include media(M) {
            padding-top: 64px;
            padding-bottom: 64px;
        }

        @include media(ML) {
            flex-direction: row;
        }

        @include media(L) {
            padding-top: 80px;
            padding-bottom: 80px;
        }
    }

    &__Media {
        align-self: center;
        width: 100%;
        position: relative;
        margin-bottom: 24px;
        box-shadow: 2px 16px 32px 0 rgba(black, .12);

        &::before {
            content: '';
            display: block;
            width: 100%;
            height: 0;
            padding-bottom: #{percentage(9/16)};
        }

        @include media(ML) {
            margin-bottom: 0;
            width: 50%;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }

    &__Player {
        position: absolute !important;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    &__Content {
        max-width: map-get($maxwidths, content);

        @include media(ML) {
            width: 50%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            padding: 0 75px;
        }
    }

    &__Term {
        @include textstyle(body-small);

        margin-bottom: 8px;
    }

    &__Title {
        @include textstyle(h2);
    }

    &__Excerpt {
        @include textstyle(body-large);

        margin-top: 8px;
    }

    &__Button {
        #{$root} & {
            margin-top: 32px;
        }
    }
}
